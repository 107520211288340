<template>
  <div>
    <lobby-list-add-new
      :is-add-new-lobby-sidebar-active.sync="isAddNewLobbySidebarActive"
      @refetch-data="refetchData"
    />
    <add-link-lobby
      :is-add-link-lobby-sidebar-active.sync="isAddLinkLobbySidebarActive"
      @refetch-data="refetchData"
      :lobbyData="lobbyData"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("labels.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("labels.entries") }}</label>
          </b-col>
          <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("labels.status") }}</label>
            <v-select
                v-model="statusSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                :options="status"
                :clearable="false"
                class="vs--single vs--searchable d-inline-block mx-50"
                style="width: 60%"
            />

          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <div style="display: none">
                <b-button variant="primary" :to="{ name: 'apps-lobbys-store' }">
                  <span class="text-nowrap">{{ $t("buttons.add") }} Old</span>
                </b-button>
              </div>


              <b-button  :to="{ name: 'apps-lobby-form-create', query: { edit: false }}"
                         class="btn mx-1" variant="primary">
                  {{ $t("buttons.add") }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay :show="loading"
                 :variant="$store.state.appConfig.layout.skin"
                 blur="2"
                 class="p-50">

      <b-table
        ref="refLobbyListTable"
        class="position-relative"
        :items="fetchLobby"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('messages.nomatching')"
        :sort-desc.sync="isSortDirDesc"
        style="min-height: 60vh"
      >
        <!-- <template #cell(backgroundcolor)="data">
          <color-table-field :color="data.lobbys.background_color">
            <image-table-field :slug="data.lobbys.background_color"></image-table-field>
          </color-table-field>
        </template> -->

        <!-- Column: Actions -->
        <template #cell(title)="data"
          ><b>{{ data.item.name }}</b></template
        >
        <template #cell(products)="data">
            <div class="d-flex flex-wrap" style="max-width: 300px; min-width: 80px;">
                <p class="ml-1" v-for="i of data.item.products">{{i.productId}}</p>
            </div>
        </template>
        <template #cell(tags)="data"
          ><span class="ml-1" v-for="i of data.item.tags">{{i.name}}</span></template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <div style="display: none">
              <b-dropdown-item
                :to="{
                  name: 'apps-lobbys-edit',
                  params: { id: data.item._id },
                }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t("buttons.edit") }} Old</span>
              </b-dropdown-item>
            </div>

              <div>
                  <b-dropdown-item
                  :to="{
                    name: 'apps-lobby-form',
                    params: { id: data.item._id },
                    query: { edit: true }
                }"
                  >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">{{ $t("buttons.edit") }}</span>
                  </b-dropdown-item>
              </div>

            <div>
              <b-dropdown-item @click="removeLobby(data.item._id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{
                  $t("buttons.delete")
                }}</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </template>
      </b-table>

      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("labels.showing") }} {{ dataMeta.from }}
              {{ $t("labels.to") }} {{ dataMeta.to }} {{ $t("labels.of") }}
              {{ dataMeta.of }} {{ $t("labels.entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalLobby"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useLobbyList from "./useLobbyList";
import lobbyStoreModule
  from "@/views/components/whitelabel-templates/App/config/PageEdit/LobbyManagement/store/lobbyStoreModule";
import AddLinkLobby from "@/views/components/whitelabel-templates/App/config/PageEdit/LobbyManagement/external/lobby/lobby-list/addLinkLobby.vue";
import LobbyListAddNew from "@/views/components/whitelabel-templates/App/config/PageEdit/LobbyManagement/external/lobby/lobby-list/lobbyListAddNew.vue";
/* import lobbyListAddNew from "./lobbyListAddNew.vue";
import addLinkLobby from "./addLinkLobby.vue"; */

export default {
  components: {
    LobbyListAddNew,
    AddLinkLobby,
   /*  lobbyListAddNew,
    addLinkLobby, */
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      nomatching: this.$t("messages.nomatching"),
    };
  },

  computed: {
    tableColumns() {
      return [
        {key: "title", sortable: true, label: this.$t("labels.title")},
        {key: "products", sortable: false, thClass:"column-products", width:"10px", label: this.$t('labels.products')},
        {key: "tags", sortable: false, label: this.$t('labels.tag')},
        {key: "actions", label: this.$t("labels.actions")},
      ]
    }
  },

  setup() {

    const HOMEPAGE_APP_STORE_MODULE_NAME = "app-lobby";

    if (!store.hasModule(HOMEPAGE_APP_STORE_MODULE_NAME))
      store.registerModule(HOMEPAGE_APP_STORE_MODULE_NAME, lobbyStoreModule);
    onUnmounted(() => {
      if (store.hasModule(HOMEPAGE_APP_STORE_MODULE_NAME))
        store.unregisterModule(HOMEPAGE_APP_STORE_MODULE_NAME);
    });

    const isAddNewLobbySidebarActive = ref(false);
    const isAddLinkLobbySidebarActive = ref(false);

    const {
      fetchLobby,
      perPage,
      currentPage,
      totalLobby,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLobbyListTable,
      refetchData,
      recordDeleted,
      lobbyData,
      loading,
      // UI

      resolveLobbyStatusVariant,
      status,
      statusSelected
    } = useLobbyList();

    return {
      // Sidebar
      isAddNewLobbySidebarActive,
      isAddLinkLobbySidebarActive,
      fetchLobby,
      perPage,
      currentPage,
      totalLobby,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLobbyListTable,
      refetchData,
      recordDeleted,
      lobbyData,
      loading,
      // UI
      resolveLobbyStatusVariant,
      status,
      statusSelected
    };
  },
  methods: {
    linkSidebar(item) {
      this.lobbyData = item;
      this.isAddLinkLobbySidebarActive = true;
    },
    removeLobby(Id) {
      store.dispatch("app-lobby/removeLobby", { id: Id }).then(() => {
        this.recordDeleted = !this.recordDeleted;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Lobby removed",
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
